import React, { forwardRef } from 'react'
import { PropTypes } from 'prop-types'
import styles from 'src/pages/generates-qr/styles/a4-qr-10x10-text.module.css'

const PER_PAGE = 70

const PrintPage = forwardRef((props, ref) => {
  const page = Array.from(
    { length: parseInt(Math.ceil(props?.data?.length / PER_PAGE)) },
    (x, i) => i * PER_PAGE
  )
  const mapItem = (page) => {
    return (
      <div className={styles.page}>
        {props?.data?.map((item, index) => {
          index++
          return (
            index > page &&
            index < page + PER_PAGE + 1 && (
              <div className={styles.label} key={index}>
                <div className={styles.qrcode}>
                  <img src={item.url} />
                  {item.expiredAt && item.limitCheck !== null
                    ? (
                      <span>{item?.uniqueCode}</span>
                      )
                    : item.expiredAt == null && item.limitCheck == null
                      ? (
                        <span>{item?.uniqueCode}</span>
                        )
                      : null}
                </div>
                <div className={styles.desc}>{props?.textInQrcode}</div>
              </div>
            )
          )
        })}
      </div>
    )
  }
  return (
    <div ref={ref} className={styles.paper} id='paper'>
      {page?.map((item, _) => {
        return mapItem(item)
      })}
    </div>
  )
})

export default PrintPage

PrintPage.propTypes = {
  data: PropTypes.array,
  textInQrcode: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ])
}
