/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Card, Col, Image, Skeleton, Descriptions, Layout, Row, Button, Tag, Select, Switch, Modal, List } from 'antd'
import PropTypes from 'prop-types'
import { PrinterOutlined, StopOutlined, CheckOutlined, ClockCircleOutlined, FileDoneOutlined, LockOutlined, UnlockOutlined, FileExcelOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { DefaultLayout } from 'src/layouts/default'
import { connect } from 'react-redux'
import { Gap } from 'src/components/atoms/Gap'
import { Header } from 'src/components/molecules/Header'
import printJS from 'print-js'
import { useReactToPrint } from 'react-to-print'
import * as XLSX from 'xlsx'

import LayoutA4QR10x10 from './layout-print/a4/QR10x10'
import LayoutA4QR20x20 from './layout-print/a4/QR20x20'
import LayoutA4QR10x10Text from './layout-print/a4/QR10x10-text'
import LayoutSample from './layout-print/a4/sample'

import LayoutA41of1QR20x20 from './layout-print/a4/1of1-20x20'
import LayoutA41of1QR30x30 from './layout-print/a4/1of1-30x30'
import LayoutA41of1QR40x40 from './layout-print/a4/1of1-40x40'

import LayoutA41of1QR20x20Text from './layout-print/a4/1of1-20x20-text'
import LayoutA41of1QR40x40Text from './layout-print/a4/1of1-40x40-text'

import Layout40x20WhitoutText from './layout-print/a3/QRContainer40x20WhitoutText'

import LayoutA3QRCont40x20 from './layout-print/a3/QRContainer40x20'
import LayoutA3QR20x20 from './layout-print/a3/QR20x20'
import LayoutA3QR10x10 from './layout-print/a3/QR10x10'
import LayoutA310x10Text from './layout-print/a3/QR10x10-text'

import LayoutA0QR10x10Text from './layout-print/a0/QRContainer10x10Text'

import helper from 'src/services/helper'
// import styles from './styles/a3-layout-40x20.module.css'
import { Address } from './address/Address'

export const _PreviewGenerate = ({ token, profile, dispatch, success }) => {
  const { state } = useLocation()
  const componentRef = useRef()

  const [printLoading, setPrintLoading] = useState(0)
  const [exportExcelLoading, setExportExcelLoading] = useState(0)
  const [detailGenerate, setDetailGenerate] = useState()
  const [loading, setLoading] = useState(0)
  const [address, setAddress] = useState(false)
  const [isModalBatchOpen, setIsModalBatchOpen] = useState(false)

  const cssPageMedia = (size = '210mm 297mm') => {
    let style
    const [pageWitdh, pageHeight] = size.split(' ')

    if (!document.getElementById('page-size')) {
      style = document.createElement('style')
      // style.setAttribute('id', 'page-size')
      document.head.append(style)
    } else {
      style = document.getElementById('page-size')
    }
    style.innerHTML = `\n
      @page {
        size: ${size};
      }\n
      @media print {\n
        html, body {\n
          width: ${pageWitdh};
          height: ${pageHeight};
        }\n
        @page {\n
          margin: 5.7mm 7.5mm;
        }\n
        
      }\n
      `
  }

  useEffect(() => {
    getDetailGenerate()
  }, [])

  useEffect(() => {
    success && getDetailGenerate()
  }, [success])

  const formatDate = (dateFormat) => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]
    const dated = new Date(dateFormat)
    const dateNumber = dated.getDate()
    const month = monthNames[dated.getMonth()]
    const year = dated.getFullYear()

    return `${dateNumber} ${month} ${year}`
  }

  // detailGenerate?.expiredAt && formatDate(detailGenerate?.expiredAt)

  const getDetailGenerate = () => {
    setLoading(true)
    helper.get(`api/v1/qrcodes/${state}`, token)
      .then((res) => {
        const datas = res.data.data
        const qrcodes = datas?.qrcodes
        if (qrcodes) {
          datas.qrcodes = qrcodes.sort((a, b) => Number(a.sku.substring(a.sku.indexOf('-') + 1, a.sku.lastIndexOf('-'))) - Number(b.sku.substring(b.sku.indexOf('-') + 1, b.sku.lastIndexOf('-'))))
        }
        // switch (qrcodes) {
        //   default:
        //     datas.qrcodes = qrcodes.sort((a, b) => Number(a.sku.substring(a.sku.indexOf('-') + 1, a.sku.lastIndexOf('-'))) - Number(b.sku.substring(b.sku.indexOf('-') + 1, b.sku.lastIndexOf('-'))))
        //     break
        // }

        setLoading(false)
        setDetailGenerate(datas)
        setLayoutPrint(res.data?.data?.size)
      })
  }

  const setLayoutPrint = (size) => {
    const settingSize = size
    switch (settingSize) {
      case 'SMALL':
        cssPageMedia()
        break

      case 'A4_10x10_TEXT':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_30x30':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20_TEXT':
        cssPageMedia()
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40_TEXT':
        cssPageMedia()
        break

      case 'A3_10x10':
        cssPageMedia('297mm 420mm')
        break

      case 'A3_10x10_TEXT':
        cssPageMedia('297mm 420mm')
        break

      case 'MEDIUM':
        cssPageMedia('297mm 420mm')
        break

      case 'LARGE':
        cssPageMedia('297mm 420mm')
        break

      case 'A0_10x10_TEXT':
        cssPageMedia('9933px 14043px')
        break

      default:
        cssPageMedia()
        break
    }
  }

  const PagePrint = () => {
    const settingSize = detailGenerate?.size
    let printPage = ''

    // const qrcodes = detailGenerate?.qrcodes ? [...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes, ...detailGenerate?.qrcodes] : []

    // const newQrcodes = [...qrcodes, ...qrcodes, ...qrcodes, ...qrcodes]

    switch (settingSize) {
      case 'SMALL':
        printPage = <LayoutA4QR10x10 ref={componentRef} data={detailGenerate?.qrcodes} />
        break

      case 'A4_10x10_TEXT':
        if (detailGenerate?.textInQrcode === '') {
          printPage = <LayoutA4QR10x10 ref={componentRef} data={detailGenerate?.qrcodes} />
        } else {
          printPage = <LayoutA4QR10x10Text ref={componentRef} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        }
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20':
        printPage = <LayoutA41of1QR20x20 ref={componentRef} data={detailGenerate?.qrcodes} />
        break

      case 'A4_1_FOR_1_PAGE_QR_30x30':
        printPage = <LayoutA41of1QR30x30 ref={componentRef} data={detailGenerate?.qrcodes} />
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40':
        printPage = <LayoutA41of1QR40x40 ref={componentRef} data={detailGenerate?.qrcodes} />
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20_TEXT':
        printPage = <LayoutA41of1QR20x20Text ref={componentRef} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40_TEXT':
        printPage = <LayoutA41of1QR40x40Text ref={componentRef} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        break

      case 'A3_10x10_TEXT':
        if (detailGenerate?.textInQrcode === '') {
          printPage = <LayoutA3QR10x10 ref={componentRef} data={detailGenerate?.qrcodes} />
        } else {
          printPage = <LayoutA310x10Text ref={componentRef} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        }
        break

      case 'MEDIUM':
        if (detailGenerate?.textInQrcode === '') {
          printPage = <Layout40x20WhitoutText ref={componentRef} data={detailGenerate?.qrcodes} />
        } else {
          printPage = <LayoutA3QRCont40x20 ref={componentRef} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        }
        break

      case 'a4_1of1_page_qr_20x20':
        printPage = <LayoutA41of1QR20x20 ref={componentRef} data={detailGenerate?.qrcodes} />
        break

      case 'A3_10x10':
        printPage = <LayoutA3QR10x10 ref={componentRef} data={detailGenerate?.qrcodes} />
        break

      case 'LARGE':
        printPage = <LayoutA3QR20x20 ref={componentRef} data={detailGenerate?.qrcodes} />
        break

      case 'A0_10x10_TEXT':
        printPage = <LayoutA0QR10x10Text ref={componentRef} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        break

      default:
        printPage = <LayoutSample ref={componentRef} data={detailGenerate?.qrcodes} textInQrcode={detailGenerate?.textInQrcode} />
        break
    }

    return printPage
  }

  const handleBeforePrint = useCallback(() => {
    setPrintLoading(0)
  }, [setPrintLoading])

  const handlePrintingonBeforeGetContent = useCallback(() => {
    setPrintLoading(1)
    // cssPageMedia('A3')
  }, [setPrintLoading])

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const onHandlePrint = useReactToPrint({
    content: reactToPrintContent,
    suppressErrors: true,
    onBeforeGetContent: handlePrintingonBeforeGetContent,
    onBeforePrint: handleBeforePrint
    // pageStyle: printPageStyle
  })

  const onHandleExcelExport = () => {
    try {
      setExportExcelLoading(1)

      const data = detailGenerate.qrcodes.map(({ sku }) => ({ Link: `${detailGenerate.urlRedirectTracking}?sku=${sku}` }))

      const worksheet = XLSX.utils.json_to_sheet(data)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')

      XLSX.writeFile(workbook, `Qrcodes-link_${detailGenerate.id}_${new Date().toISOString()}.xlsx`, { compression: true })
    } catch (error) {
      console.log(error)
    } finally {
      setExportExcelLoading(0)
    }
  }

  const onHandlePrintForPrintJS = () => {
    printJS({
      modalMessage: 'Printing QR...',
      printable: 'paper',
      type: 'html',
      showModal: true,
      css: './styles/a3-layout-40x20.module.css',
      font_size: 5
      // imageStyle: 'width: 14.5mm; height: 14.5mm; float: left;',
      // gridStyle: 'margin:0; padding:0'
    })
  }

  const onChangeStatus = (val) => {
    const newData = {
      printStatus: val
    }
    dispatch({
      type: 'generate/update_request',
      payload: {
        token,
        generateId: state,
        data: JSON.stringify(newData)
      }
    })
  }

  const getTitleLayoutPrint = (layoutPrint) => {
    let title
    switch (layoutPrint) {
      case 'SMALL':
        title = 'A4 : QR Code Size 10x10mm'
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20':
        title = 'A4 : A4 : 1 QR Code for 1 Page - QR Code Size 20x20mm'
        break

      case 'A4_1_FOR_1_PAGE_QR_30x30':
        title = 'A4 : A4 : 1 QR Code for 1 Page - QR Code Size 30x30mm'
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40':
        title = 'A4 : A4 : 1 QR Code for 1 Page - QR Code Size 30x30mm'
        break

      case 'A4_1_FOR_1_PAGE_QR_20x20_TEXT':
        title = 'A4 : A4 : 1 QR Code for 1 Page - QR Code Size 20x20mm with Text'
        break

      case 'A4_1_FOR_1_PAGE_QR_40x40_TEXT':
        title = 'A4 : A4 : 1 QR Code for 1 Page - QR Code Size 40x40mm with Text'
        break

      case 'MEDIUM':
        title = 'A3 : QR Code SIze 20x20mm with Text'
        break

      case 'LARGE':
        title = 'A3 : QR Code Size 20x20mm'
        break

      case 'A3_10x10':
        title = 'A3 : QR Code Size 10x10mm'
        break

      case 'A3_10x10_TEXT':
        title = 'A3 : QR Code Size 10x10mm with Text'
        break

      case 'A0_10x10_TEXT':
        title = 'A0 : QR Code Size 10x10mm with Text'
        break

      default:
        title = layoutPrint
        break
    }

    return title
  }

  const handleChangeBatchLock = (lock) => {
    const newData = {
      batchLock: lock
    }

    dispatch({
      type: 'generate/update_request',
      payload: {
        token,
        generateId: state,
        data: JSON.stringify(newData)
      }
    })
  }

  // GENERATING
  // <Button loading={printLoading} onClick={onHandlePrint} type='primary' block icon={<PrinterOutlined />} style={{ backgroundColor: '#293AB4', borderColor: '#293AB4' }}>Print</Button>}>
  const extra = (
    <div className='d-flex'>
      {(profile?.role === 'platinum_master' || profile?.role === 'sub_account') && <Button onClick={() => setAddress(!address)} className='mr-2' type='primary' block icon={<PrinterOutlined />} style={{ background: 'linear-gradient(45deg, #2B33B2, #1486C9)' }}>Print by OPIM</Button>}
      <Button loading={printLoading} onClick={onHandlePrint} type='primary' block icon={<PrinterOutlined />} className='mr-2' style={{ background: 'linear-gradient(45deg, #2B33B2, #1486C9)' }}>Print</Button>
      <Button loading={exportExcelLoading} onClick={onHandleExcelExport} type='primary' block icon={<FileExcelOutlined />} style={{ background: 'linear-gradient(45deg, #2B33B2, #1486C9)' }}>Excel</Button>
    </div>
  )

  return (
    <DefaultLayout>
      {detailGenerate?.batchSize > 1 && (
        <Modal title={`Batch Size is ${detailGenerate?.batchSize}`} visible={isModalBatchOpen} okButtonProps={{ style: { display: 'none' } }} onCancel={() => setIsModalBatchOpen(false)}>
          <List
            size='default'
            bordered
            dataSource={[...Array.from({ length: detailGenerate?.batchSize }, (_, i) => i + 1)].map((item) => {
              return {
                batch: `Batch ${item}`,
                count: detailGenerate?.batchCount[item] || 0
              }
            })}
            renderItem={({ batch, count }) => (
              <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{batch}</span>
                <span>{count}</span>
              </List.Item>
            )}
          />
        </Modal>
      )}
      <Layout.Content className='container-detail-product'>
        <Card>
          <Descriptions
            column={2} title='Detail QR' extra={extra}
          >
            <Descriptions.Item label='Status'>
              <Skeleton loading={loading}>
                {detailGenerate?.status === 'GENERATED'
                  ? <Tag color='green'>{detailGenerate?.status}</Tag>
                  : <Tag color='orange'>{detailGenerate?.status}</Tag>}
              </Skeleton>
            </Descriptions.Item>
            <Descriptions.Item label='Production Date'>
              <Skeleton loading={loading}>
                {/* {detailGenerate?.productionDate} */}
                {formatDate(detailGenerate?.productionDate)}
              </Skeleton>
            </Descriptions.Item>
            <Descriptions.Item label='Total'>
              <Skeleton loading={loading}>
                {detailGenerate?.total}
              </Skeleton>
            </Descriptions.Item>
            {/* <Descriptions.Item label='Expired Date'>
              <Skeleton loading={loading}>
                {detailGenerate?.expiredAt}
                {detailGenerate?.expiredAt ? formatDate(detailGenerate?.expiredAt) : '-'}
              </Skeleton>
            </Descriptions.Item> */}
            <Descriptions.Item label='ID Generate QR Code'>
              <Skeleton loading={loading}>
                {detailGenerate?.customId ? detailGenerate.customId : detailGenerate?.id}
              </Skeleton>
            </Descriptions.Item>
            <Descriptions.Item label='QR Code Layout Print'>
              <Skeleton loading={loading}>
                {getTitleLayoutPrint(detailGenerate?.size)}
              </Skeleton>
            </Descriptions.Item>
            <Descriptions.Item label='Print Status'>
              <Skeleton loading={loading}>
                {detailGenerate?.printRequests.length === 0
                  ? (
                    <Select
                      placeholder='Select a option and change input text above'
                      defaultValue='NOT_PRINTED'
                      style={{
                        width: 140
                      }}
                      value={detailGenerate?.printStatus}
                      onSelect={onChangeStatus}
                    >
                      <Select.Option value='NOT_PRINTED'>
                        <div className='d-flex' style={{ alignItems: 'center' }}>
                          <StopOutlined className='mr-2' style={{ color: '#EB5757' }} /> Not Printed
                        </div>
                      </Select.Option>
                      <Select.Option value='PRINTED'>
                        <div className='d-flex' style={{ alignItems: 'center' }}>
                          <CheckOutlined className='mr-2' style={{ color: '#27AE60' }} /> Printed
                        </div>
                      </Select.Option>
                    </Select>
                    )
                  : (
                    <>
                      {detailGenerate?.printRequests[0]?.status === 'NOT_PRINTED' && <div className='d-flex' style={{ alignItems: 'center', border: '1px solid #E0E0E0', borderRadius: 6, padding: '2px 8px' }}><StopOutlined className='mr-2' style={{ color: '#EB5757' }} />Not Printed</div>}
                      {detailGenerate?.printRequests[0]?.status === 'IN_QUEUE' && <div className='d-flex' style={{ alignItems: 'center', border: '1px solid #E0E0E0', borderRadius: 6, padding: '2px 8px' }}><ClockCircleOutlined className='mr-2' style={{ color: '#F2994A' }} />In Queue</div>}
                      {detailGenerate?.printRequests[0]?.status === 'PRINTED' && <div className='d-flex' style={{ alignItems: 'center', border: '1px solid #E0E0E0', borderRadius: 6, padding: '2px 8px' }}><CheckOutlined className='mr-2' style={{ color: '#27AE60' }} />Printed</div>}
                      {detailGenerate?.printRequests[0]?.status === 'DELIVERING' &&
                      (
                        <div className='d-flex' style={{ alignItems: 'center', border: '1px solid #E0E0E0', borderRadius: 6, padding: '2px 8px' }}>
                          <svg className='mr-2' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M13.3334 11.333H14.6667V9.10634C14.667 8.75605 14.5982 8.40913 14.4644 8.08541C14.3306 7.76169 14.1343 7.46751 13.8867 7.21967L12.6667 5.99967H9.33337V11.333H10M6.66671 11.333H9.33337V3.33301H1.33337V11.333H3.33337H6.66671Z' stroke='#21BADF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                            <path d='M5.00004 13.3333C5.92052 13.3333 6.66671 12.5871 6.66671 11.6667C6.66671 10.7462 5.92052 10 5.00004 10C4.07957 10 3.33337 10.7462 3.33337 11.6667C3.33337 12.5871 4.07957 13.3333 5.00004 13.3333Z' stroke='#21BADF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                            <path d='M11.6667 13.3333C12.5871 13.3333 13.3333 12.5871 13.3333 11.6667C13.3333 10.7462 12.5871 10 11.6667 10C10.7462 10 10 10.7462 10 11.6667C10 12.5871 10.7462 13.3333 11.6667 13.3333Z' stroke='#21BADF' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                          </svg>Delivering
                        </div>)}
                      {detailGenerate?.printRequests[0]?.status === 'RECEIVED' && <div className='d-flex' style={{ alignItems: 'center', border: '1px solid #E0E0E0', borderRadius: 6, padding: '2px 8px' }}><FileDoneOutlined className='mr-2' style={{ color: '#27AE60' }} />Received</div>}
                    </>
                    )}
              </Skeleton>
            </Descriptions.Item>
            {detailGenerate?.batchSize > 1 && (
              <>
                <Descriptions.Item label='Batch Size'>
                  <Skeleton loading={loading}>
                    <Button type='primary' size='small' onClick={() => setIsModalBatchOpen(true)}>{detailGenerate?.batchSize}</Button>
                  </Skeleton>
                </Descriptions.Item>
                <Descriptions.Item label='Batch Lock'>
                  <Skeleton loading={loading}>
                    <Switch
                      checkedChildren={<LockOutlined />}
                      unCheckedChildren={<UnlockOutlined />}
                      checked={detailGenerate?.batchLock}
                      onChange={handleChangeBatchLock}
                    />
                  </Skeleton>
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
          {detailGenerate?.printRequests[0]?.expedition && detailGenerate?.printRequests[0]?.expedition &&
          (
            <div className='receipt d-flex'>
              <div className='ant-col ant-col-12' style={{ alignSelf: 'center' }}>
                <div className='d-flex mb-2'>
                  <div style={{ width: '30%', fontWeight: 400 }}>
                    Receipt Number
                  </div>
                  <div style={{ width: '5%' }}>
                    :
                  </div>
                  <div style={{ width: '65%' }}>
                    {detailGenerate?.printRequests[0]?.noReceipt}
                  </div>
                </div>
                <div className='d-flex'>
                  <div style={{ width: '30%', fontWeight: 400 }}>
                    Expedition
                  </div>
                  <div style={{ width: '5%' }}>
                    :
                  </div>
                  <div style={{ width: '65%' }}>
                    {detailGenerate?.printRequests[0]?.expedition}
                  </div>
                </div>
              </div>
              <Col span={12}>
                <div className='d-flex' style={{ alignItems: 'center' }}>
                  <div style={{ width: '30%' }}>
                    Address
                  </div>
                  <div style={{ width: '5%' }}>
                    :
                  </div>
                  <div style={{ width: '65%' }}>
                    <div className='address' onClick={() => setVisible(!visible)}>
                      <h3 className='d-flex address-title' style={{ alignItems: 'center' }}>{detailGenerate?.printRequests[0]?.address.address} <span className='ml-1' style={{ fontSize: 11, fontWeight: 400 }}>({detailGenerate?.printRequests[0]?.address.phone.indexOf('0') === 0 ? detailGenerate?.printRequests[0]?.address.phone.replace('0', '+62') : detailGenerate?.printRequests[0]?.address.phone})</span></h3>
                      <div style={{ fontWeight: 400 }}>
                        {detailGenerate?.printRequests[0]?.address.address}, {detailGenerate?.printRequests[0]?.address.city}, {detailGenerate?.printRequests[0]?.address.district}, {detailGenerate?.printRequests[0]?.address.province}, ID {detailGenerate?.printRequests[0]?.address.zip}
                      </div>
                      {detailGenerate?.printRequests[0]?.address.description && (
                        <div className='address-description p-2 mt-2'>
                          {detailGenerate?.printRequests[0]?.address.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </div>)}
          <Row gutter={16}>
            <Col md={24}>
              <p>Distros :</p>
              <Row gutter={16}>
                {
                detailGenerate?.distros.map(item =>
                  <Col md={7} key={item.id}>
                    <Card loading={loading} hoverable style={{ fontSize: 11 }}>
                      <div className='d-flex'>
                        <div style={{ width: '30%' }}>
                          Name
                        </div>
                        <div style={{ width: '5%' }}>
                          :
                        </div>
                        <div style={{ width: '65%', fontWeight: 600 }}>
                          {item.name}
                        </div>
                      </div>
                      <Gap height='.4rem' />
                      <div className='d-flex'>
                        <div style={{ width: '30%' }}>
                          Owner
                        </div>
                        <div style={{ width: '5%' }}>
                          :
                        </div>
                        <div style={{ width: '65%', fontWeight: 600 }}>
                          {item.owner}
                        </div>
                      </div>
                      <Gap height='.4rem' />
                      <div className='d-flex'>
                        <div style={{ width: '30%' }}>
                          Code
                        </div>
                        <div style={{ width: '5%' }}>
                          :
                        </div>
                        <div style={{ width: '65%', fontWeight: 600 }}>
                          {item.code}
                        </div>
                      </div>
                      <Gap height='.4rem' />
                      <div className='d-flex'>
                        <div style={{ width: '30%' }}>
                          Address
                        </div>
                        <div style={{ width: '5%' }}>
                          :
                        </div>
                        <div style={{ width: '65%', fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {item.address}
                        </div>
                      </div>
                      <Gap height='.4rem' />
                      <div className='d-flex'>
                        <div style={{ width: '30%' }}>
                          Created At
                        </div>
                        <div style={{ width: '5%' }}>
                          :
                        </div>
                        <div style={{ width: '65%', fontWeight: 600 }}>
                          {item.createdAt}
                        </div>
                      </div>
                    </Card>
                    <Gap height='1em' />
                  </Col>
                )
              }
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24}>
              <p>QRCodes :</p>
              <Row gutter={12}>
                {
                  detailGenerate?.qrcodes.map((item, index) =>
                    <Skeleton key={index} loading={loading}>
                      <Col md={4}>
                        <Card
                          hoverable
                          style={{ padding: 1 }}
                          cover={
                            <Image
                              key={index}
                              src={item?.url}
                            />
                        }
                          bodyStyle={{ padding: 12 }}
                        >
                          <Card.Meta
                            // title={(detailGenerate.expiredAt && item?.limitCheck) ? item?.uniqueCode : ''}
                            title={item?.uniqueCode ? item?.uniqueCode : ''}
                            description={[
                              <Row key={1} justify='space-between'>
                                <p style={{ margin: 0, fontSize: '.8rem' }}>{item?.sku}</p>
                                {detailGenerate?.batchSize > 1 && <p style={{ margin: 0, fontSize: '.8rem' }}>Batch: {item?.batch}</p>}
                              </Row>,
                              <Row key={2} justify='space-between'>
                                <p style={{ margin: 0, fontSize: '.8rem' }}>Total: {item?.totalChecked}</p>
                                <p style={{ margin: 0, fontSize: '.8rem' }}>Limit: {item?.limitCheck}</p>
                              </Row>
                            ]}
                          />
                        </Card>
                        <Gap height='1rem' />
                      </Col>
                    </Skeleton>
                  )
                }
              </Row>
            </Col>
          </Row>
        </Card>
        <Address
          address={address}
          token={token}
          onCancel={() => setAddress(!address)}
          profile={profile}
        />
      </Layout.Content>
      <div style={{ display: 'none' }}>
        {/* <PrintGenerate ref={(el) => (componentRef = el)} data={detailGenerate?.qrcodes} /> */}
        <PagePrint />
      </div>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ reducerGenerateQR, reducerProfiles }) => {
  return { ...reducerGenerateQR, ...reducerProfiles }
}

export const PreviewGenerate = connect(mapStateToProps)(_PreviewGenerate)

_PreviewGenerate.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.string,
  profile: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.array]),
  success: PropTypes.bool
}
